<template>
  <div>
    <titleLink title="疫情信息" :onClick="onClickBack"></titleLink>
    <div style="padding:.3rem .2rem 3rem .3rem;height:100vh;overflow:hidden;overflow-y:scroll;margin-top:.7rem">
      <card style="margin-top:.3rem">
        <template #content>
          <van-row style="line-height:15px;height:30px">
            <span class="plain-txt">{{province}}疫情</span>
          </van-row>
          <van-grid style="margin-top:.1rem" column-num="4">
             <van-grid-item v-for="item, idx in cards" :key="idx">
              <dataCell 
                :color="item.color" :bgColor="item.bgColor" :title="item.title"
                :value.sync="item.value" :badge.sync="item.badge"
                ></dataCell> 
             </van-grid-item>
          </van-grid>
        </template>
      </card>
      <card style="margin-top:.2rem">
        <template #content>
          <van-row style="line-height:15px">
            <span class="info-txt">&nbsp;&nbsp;&nbsp;统计截至：{{data.ncov2.lastUpdateTime}}</span>
          </van-row>
          <areaTable :data="provinceData.children" :isChild="true" style="margin-top:.3rem"></areaTable>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import titleLink from "@/views/components/TitleLink";
import areaTable from './areaDataTable.vue'
import dataCell from "./dataCell.vue"
import api from '@/services/apis'
export default {
  components: {
    card, titleLink, areaTable, dataCell
  },
  data() {
    return {
      provinceData: {},
      province: "",
      data: {
        ncov2: {
          lastUpdateTime: ""
        }
      },
      provinceCompare: {},
      cards: [
        {
          title: "现有确诊",
          color: "#e61c1d",
          value: 0,
          badge: 0,
          bgColor: "#fff8f8"
        },
        {
          title: "本土无症状",
          value: 0,
          badge: 0,
          color: "#ae3ac6",
          bgColor: "#fef7ff"
        },
        {
          title: "累计确诊",
          value: 0,
          badge: 0,
          color: "#be2121",
          bgColor: "#fff4f4"
        },
        {
          title: "累计死亡",
          value: 0,
          badge: 0,
          color: "#4e5a65",
          bgColor: "#f3f6f8"
        },
      ]
    }
  },
  mounted() {
    this.province = this.$route.query.province
    api.yiqing_info().then(res => {
      this.data = res.data
      this.provinceCompare = this.data.ncov5.provinceCompare
      this.data.ncov2.areaTree[0].children.forEach(ele => {
        if(ele.name == this.province) {
          this.provinceData = ele
          this.fillCardsData()
        }
      })
    })
  },
  methods: {
    onClickBack() {
      this.$router.push('/yiqing/main')
    },
    fillCardsData() {
      const provinceDiff = this.provinceCompare[this.province]
      this.cards[0].value = this.provinceData.total.nowConfirm
      this.cards[0].badge = provinceDiff.nowConfirm
      this.cards[1].value = this.provinceData.total.wzz
      this.cards[1].badge = this.provinceData.today.wzz_add
      this.cards[2].value = this.provinceData.total.confirm
      this.cards[2].badge = provinceDiff.confirmAdd
      this.cards[3].value = this.provinceData.total.dead
      this.cards[3].badge = provinceDiff.dead
    },
  }
}
</script>

<style scoped>
.info-txt {
  color: #666666;
  font-size: 12px;
}
.plain-txt {
  font-size: 15px;
  font-weight: bold;
}
</style>